<template>
  <NavQuestion
    v-bind:heading="'Do you want the Storage, Printing and Updates enhancement?'"
    v-bind:background="'bg-basket'"
    v-bind:subHeading="
      'Your Will needs to be updated as things change, that\'s why for only ' +
      packagePrice +
      ' per year, you can login and update your Will whenever you need to and we will re-check and re-print your Will. Also, with this popular package your Will can be stored securely, ensuring it will never be lost or damaged (you will also have a copy to kept at home). This package also includes printing and binding.'
    "
    v-bind:number="'1'"
    v-bind:questionOptions="true"
  >
    <template v-slot:contents>
      <InputOptionLarge
        v-bind:letter="'a'"
        v-bind:selected="storage === true"
        v-bind:option="'Yes'"
        v-bind:pricing="packagePrice"
        v-bind:subPricing="'Per Year'"
        v-bind:image="'https://mylastwill.s3.amazonaws.com/static/img/button-basket-package.png'"
        :popular="true"
        @selected="setYes"
      >
        <template v-slot:text>
          <div class="pricing-sub-text">
            One package with three fantastic benefits...
          </div>
          <div class="text-long-heading">Secure Storage</div>
          <div class="pricing-sub-text">
            Return your signed Will and we will store it securely; this ensures
            you Will is found and your wishes followed.
          </div>
          <div class="text-long-heading">Unlimited Updates</div>
          <div class="pricing-sub-text">
            Log-in and update your Will as your circumstances change (includes
            re-checking and re-printing).
          </div>
          <div class="text-long-heading">Printing and Binding</div>
          <div class="pricing-sub-text">
            We will print, bind and post your documents ready for signing. Two
            copies are provided one to sign and one for your records.
          </div>
        </template>
      </InputOptionLarge>

      <InputOptionLarge
        v-bind:letter="'b'"
        v-bind:selected="storage === false"
        v-bind:option="'No'"
        v-bind:image="'https://mylastwill.s3.amazonaws.com/static/img/button-basket-no-package.png'"
        @selected="setNo"
      >
        <template v-slot:text>
          {{ noText }}
        </template>
      </InputOptionLarge>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="validatePage"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="'/create_will'"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import NavQuestion from '@/common/ui/NavQuestion'
import InputOptionLarge from '@/common/ui/InputOptionLarge'
import { checkoutHelpers } from '@/common/mixins/checkoutHelpers'

export default {
  name: 'StoragePage',
  mixins: [checkoutHelpers],
  components: {
    NavQuestionButtons,
    NavQuestion,
    InputOptionLarge
  },
  mounted() {
    this.$store.commit('checkoutProgress', {
      createWill: true
    })
    this.storage = this.$store.getters.checkoutBasket.package
    if (!this.$store.getters.willPreview) {
      this.$store.dispatch('willPreviewFetch')
    }
  },
  data() {
    return {
      storage: null
    }
  },
  computed: {
    validatePage() {
      if (this.storage || this.storage === false) return true
      return false
    },
    will() {
      if (this.$store.getters.willPreview)
        return this.$store.getters.willPreview.will_ob
      return null
    },
    basket() {
      return this.$store.getters.checkoutBasket
    },
    packagePrice() {
      return '£' + this.$store.getters.prices.package_yearly / 100
    },
    updateSinglePrice() {
      return this.$store.getters.prices.update_single / 100
    },
    forwardTo() {
      if (this.storage) return '/checking'
      return '/printing'
    },
    noText() {
      if (
        this.will &&
        !this.will.update &&
        this.basket.single_price === this.$store.getters.prices.update_single
      )
        return (
          'Without the update package this Will costs £' +
          this.updateSinglePrice
        )
      return (
        'You will have to download and print your Will; it will not be stored securely and future updates will cost £' +
        this.updateSinglePrice +
        '.'
      )
    }
  },
  watch: {
    basket(value) {
      this.storage = value.package
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.storage) {
      this.saveCheckoutOptions()
    }
    next()
  },
  methods: {
    setYes() {
      this.storage = true
      this.$store.commit('checkoutBasket', {
        package: true,
        printing: false
      })
    },
    setNo() {
      this.storage = false
      this.$store.commit('checkoutBasket', {
        package: false
      })
    }
  }
}
</script>
